import _ from 'lodash';

import { BittsSizeMap } from '../types';

export const Status = {
  Neutral: 'neutral',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Danger: 'danger',
  Error: 'error',
} as const;
export type IStatus = (typeof Status)[keyof typeof Status];

export const Size = _.pick(BittsSizeMap, ['Small', 'Medium']);
export type ISize = (typeof Size)[keyof typeof Size];
