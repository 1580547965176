import { StrictExclude } from '@crossbeam/types';

import { omit } from 'lodash';

import { BittsSizeMap } from '../types';

export const Shape = {
  Circle: 'circle',
  Square: 'square',
} as const;
export type TShape = (typeof Shape)[keyof typeof Shape];

export const Size = omit(BittsSizeMap, 'XLarge');
export type TSize = StrictExclude<
  (typeof BittsSizeMap)[keyof typeof BittsSizeMap],
  'x-large'
>;

export type TOrganization = {
  domain?: string;
  clearbit_domain?: string;
  logo_url?: string;
  name?: string;
};

export type TUser = {
  picture_url?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
};
